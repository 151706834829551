<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/announcement") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 公告設定

        b-card-body(v-if="!loading")
          form.form-horizontal(role="form")
            .row
              .col-lg-5
                .form-group.mb-3
                  label 公告分類
                  br
                  .row
                    .col-12.col-lg-6.mb-2.mb-lg-0
                      multiselect(
                        v-if="announceId === 'create' || !isLock"
                        :value="announceDetail.type",
                        @input="onTypeChanged"
                        track-by="id",
                        label="text",
                        :options="main_options",
                        placeholder="選擇主分類",
                        select-label=""
                      )
                      input.form-control(
                        v-else
                        v-model="announceDetail.type.text",
                        type="text",
                        disabled
                      )
                    .col-12.col-lg-6.mb-2.mb-lg-0
                      multiselect(
                        v-if="announceId === 'create' || !isLock"
                        v-model="announceDetail.content_type",
                        track-by="id",
                        label="text",
                        :options="content_options",
                        placeholder="選擇次分類",
                        select-label=""
                      )
                      input.form-control(
                        v-else
                        v-model="announceDetail.content_type.text",
                        type="text",
                        disabled
                      )

            .row
              .col-lg-8
                .form-group.mb-3
                  label 公告標題
                  br
                  input.form-control(
                    v-model="announceDetail.title",
                    type="text",
                    placeholder="請輸入公告標題",
                    :disabled="announceId !== 'create' && isLock"
                  )
            .row
              .col-lg-5
                .form-group.mb-3
                  label 上架日期
                  br
                  .row
                    .col-12.col-lg-6.mb-2.mb-lg-0
                      date-picker(
                        v-model="announceDetail.start_date",
                        format="YYYY-MM-DD"
                        value-type="format",
                        append-to-body,
                        lang="zh",
                        placeholder="請選擇公告起始日期"
                        :disabled="announceId !== 'create' && isLock"
                      )
                    .col-12.col-lg-6.mb-2.mb-lg-0
                      date-picker(
                        v-model="announceDetail.start_time",
                        format="HH:mm",
                        :is-24="true"
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                        :disabled="announceId !== 'create' && isLock"
                      )
            .row
              .col-lg-5
                .form-group.mb-3
                  label 下架日期
                  br
                  .row
                    .col-12.col-lg-6.mb-2.mb-lg-0
                      date-picker(
                        v-model="announceDetail.end_date",
                        format="YYYY-MM-DD"
                        value-type="format",
                        append-to-body,
                        lang="zh",
                        placeholder="請選擇公告下架日期"
                        :disabled="announceId !== 'create' && isLock"
                      )
                    .col-12.col-lg-6.mb-2.mb-lg-0
                      date-picker(
                        v-model="announceDetail.end_time",
                        format="HH:mm",
                        :is-24="true"
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                        :disabled="announceId !== 'create' && isLock"
                      )

            .row
              .col-lg-10
                #ckeditor-editor.form-group.mb-3
                  label.form-label-row.justify-content-between
                    span 正文編輯
                  //- 稿件本文編輯區域
                  //- ckeditor(:editor="editor",v-model="announceDetail.content")
            .row 
              .col(v-if="isLock")
                b-button.action-btn.width-lg.mr-1(variant="primary" @click="isLock = false") 我要編輯
              .col(v-else)
                b-button.action-btn.width-lg.mr-1(variant="primary" @click="onAnnounceSave" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
                b-button.action-btn.width-lg(variant="primary" @click="releaseAnnounce" :disabled="!announceDetail.id || releasing") {{ releasing ? '發佈中...' : '發佈' }}
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      // 文章內容
      articleContent: "",
      title: "公告/文章編輯",
      items: [
        {
          text: "公告",
          href: "/announcement",
        },
        {
          text: "公告列表",
          href: '/announcement',
        },
        {
          text: "公告編輯",
          active: true,
        }
      ],
      loading: true,
      isLock: false,
      announceId: '',
      announceDetail: {
        // id: "",
        type: {},
        content_type: {},
        title: "",
        content: "",
        start_date: null,
        start_time: "",
        end_date: null,
        end_time: "",
        // status: 1,
        // ann_status: 1
      },

      main_options: [
        { id: 1, text: '新聞稿', value: 1 },
        { id: 2, text: '營運公告', value: 2 },
        { id: 3, text: '節目公告', value: 3 },
      ],

      content_options_1: [
        { id: 1, text: '澄清聲明', value: 1 },
        { id: 2, text: '更正啟事', value: 2 },
        { id: 3, text: '公告', value: 3 },
      ],
      content_options_2: [
        { id: 1, text: '營運動態', value: 1 },
      ],
      content_options_3: [
        { id: 1, text: '節目動態', value: 1 },
      ],

      content_options: [],

      value: '',
      date: '',
      time: '',
      visible: true,
      option: '',

      saving: false,
      releasing: false
    };
  },
  created () {
    // 獲得操作公告ID
    this.announceId = this.$route.params.NewAnnounceId
    // 獲得單一公告文章
    this.getAnnounceDetail()
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2
  },
  methods: {
    // 當 type 被選擇時
    onTypeChanged (_select) {
      if (_select && _select !== this.announceDetail.type) {
        this.content_options = this.getContentOptionsByType(_select.id)
      }
      this.$set(this.announceDetail, 'content_type', {})
      this.$set(this.announceDetail, 'type', _select)
    },
    // 取得 type, content_type 對應的 object
    getTypeObjectById (_options, _id) {
      return _id ? _options.find(_option => _option.id === _id) : {}
    },
    // 取得目前使用的 content_option
    getContentOptionsByType (_typeId) {
      switch (_typeId) {
        case 1:
          return this.content_options_1
        case 2:
          return this.content_options_2
        case 3:
          return this.content_options_3
      }
    },
    // 獲得單一公告
    getAnnounceDetail () {
      if (this.announceId == 'create') {
        this.loading = false
        this.isLock = false
        return
      }
      this.isLock = true
      this.$root.apis.getAnnounceDetail(this.announceId, this.getNewsDetailSuccess)
    },
    getNewsDetailSuccess (_response) {
      // 用 type, content_type 的 id 找到選項 object
      const typeId = _response.body.data.type
      const typeObject = this.getTypeObjectById(this.main_options, typeId)
      const contentTypeId = _response.body.data.content_type
      const currentOptions = this.getContentOptionsByType(typeId)
      const contentTypeObject = this.getTypeObjectById(currentOptions, contentTypeId)

      this.announceDetail = {
        ..._response.body.data,
        type: typeObject,
        content_type: contentTypeObject
      }
      this.content_options = currentOptions
      this.loading = false
    },
    onAnnounceSave () {
      if (!this.checkAnnounceData()) {
        this.$root.common.showErrorDialog("請確定必填欄位都有填寫完成哦！")
        return
      }
      const post_data = {
        type: this.announceDetail.type.value,
        content_type: this.announceDetail.content_type.value,
        title: this.announceDetail.title,
        content: this.announceDetail.content,
        start_date: this.announceDetail.start_date,
        start_time: this.announceDetail.start_time,
        end_date: this.announceDetail.end_date,
        end_time: this.announceDetail.end_time,
      }
      if (this.announceId === 'create') {
        this.createAnnounce(post_data)
      } else {
        this.updateAnnounce(post_data)
      }
    },
    // 建立公告
    createAnnounce (_data) {
      this.saving = true
      this.$root.apis.createAnnounceDetail(_data, this.createAnnounceSuccess, this.announceError)
    },
    createAnnounceSuccess (_response) {
      this.$set(this.announceDetail, 'id', _response.body.data)
      this.$root.common.showSingleNotify("儲存成功！")
      this.saving = false
    },
    announceError (_error) {
      const errorMsg = typeof _error.body.data === 'object' ? '請確定必填欄位都有填寫完成哦！' : _error.body.data
      this.$root.common.showErrorNotify(errorMsg)
      this.saving = false
    },
    // 更新公告
    updateAnnounce (_data) {
      this.saving = true
      this.$root.apis.updateAnnounceDetail(this.announceId, _data, this.updateAnnounceSuccess, this.announceError)
    },
    updateAnnounceSuccess (_response) {
      this.$root.common.showSingleNotify("更新成功！")
      this.saving = false
    },
    // 檢查必填
    checkAnnounceData () {
      const requiredData = ['type', 'content_type', 'title', 'content', 'start_date', 'start_time']
      const invalidFieldExist = requiredData.some(_field => {
        return typeof this.announceDetail[_field] === 'object'
          ? !this.announceDetail[_field]?.id
          : !this.announceDetail[_field]
      })
      if (invalidFieldExist) return false
      return true
    },
    // 發佈公告
    releaseAnnounce () {
      if (this.announceDetail.id) {
        this.releasing = true
        this.$root.apis.releaseAnnounceDetail(this.announceDetail.id, this.releaseAnnounceSuccess, this.releaseannounceError)
      }
    },
    releaseAnnounceSuccess (_response) {
      this.$root.common.showSingleNotify('公告新增完成！')
      this.releasing = false
    },
    releaseannounceError (_error) {
      this.$root.common.showErrorNotify(_error.body.data)
      this.releasing = false
    },
  }
};
</script>


<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 1rem 0
</style>
