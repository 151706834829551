<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  NewsDetailModal(
    v-if="showContentModal" 
    :modname="currentModalName" 
    :content="currentContent" 
  )
  .row
    .col-lg-12.mb-2.title-box
      TimePeriodTab(
        :tab="statusTab" 
        :customerReng="true"
        @tab-change="changeStatusBar"
        @set-start-date="setStartDateChange"
        @set-end-date="setEndDateChange"        
      )

    .col-lg-12
      .card(v-if="($root.user.permission.indexOf('DCENTER_TOTAL_SITE_ANALYSIS') > -1)")
        .card-body
          .header-row.mb-3
            .header-title 全站區間統計
          .row
            .col-lg-4
              .form-group.mb-3
                label 新聞網全站稿件數量
                  small (已上架)
                br
                input.form-control(
                  :value="$root.common.formatNumber(article_count,0)", 
                  type="text", 
                  disabled
                )
            .col-lg-4
              .form-group.mb-3
                label 新聞網全站觀看總數
                br
                input.form-control(
                  :value="$root.common.formatNumber(article_views,0)",
                  type="text",
                  disabled
                )

      .card(v-if="($root.user.permission.indexOf('DCENTER_TYPE_ARTICLE_ANALYSIS') > -1)")
        .card-body
          .header-row.mb-3
            .header-title 分類區間統計
          .row
            .col-lg-3(
              v-for="( type, index ) in articleTypes"
              v-if="type.id"
            )
              .row
                .col-lg-12
                  .form-group.mb-3
                    label {{ type.name }} 稿件數量 / 觀看總數
                    br
                    input.form-control(v-if="type_counts.length <= 0" type="text",placeholder="讀取中",disabled)
                    input.form-control(
                      v-for="typeArticle in type_counts"
                      v-if="typeArticle.type_id === type.id"
                      type="text",disabled
                      :value="$root.common.formatNumber(typeArticle.article_count,0) + ' / ' +  $root.common.formatNumber(typeArticle.article_views,0)"
                    )

      .card
        .card-body
          .row 
            .col-lg-12
              p.font-weight-normal 新聞分類
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-radio-group#checkbox-group-1(
                    v-model="search_article_type"
                    :checked="searchData.type"
                    name="search_article_type"
                  )
                    b-form-radio(
                      v-for="_option in articleTypes" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-12
              p.font-weight-normal 稿件來源
              .checkbox-group.col-lg-12
                b-form-group
                  b-form-radio-group#checkbox-group-1(
                    v-model="search_article_source"
                    :checked="searchData.type"
                    name="search_article_source"
                    )
                    b-form-radio(
                      v-for="_option in articleSource" 
                      :key="_option.id" 
                      :value="_option.id"
                    ) {{_option.name}}
            .col-lg-4
              .form-group.mb-3(v-if="($root.user.permission.indexOf('DCENTER_QUERY_OTHER_EDITER') > -1)")
                label 上稿人員
                br
                multiselect(
                  v-model="currentEditer",
                  :options="editer",
                  placeholder="請選擇人員",
                  label="name",
                  track-by="id",
                  @search-change="searchEditerList",
                  select-label=""
                )
              .form-group.mb-3(v-else)
                label 上稿人員
                br
                input.form-control(disabled,:value="$root.user.name")
            .col-lg-4
              .form-group.mb-3
                label 掛名中心
                br
                select.form-control(
                    v-model="currentEditerName",
                    type="text",
                    placeholder="請選擇掛稿中心",
                  )
                    option(value="") 請選擇中心
                    option(value="即時中心") 即時中心
                    option(value="要聞中心") 要聞中心
                    option(value="娛樂中心") 娛樂中心
                    option(value="大國中心") 大國中心
                    option(value="健康中心") 健康中心

          b-button.width-md(variant="primary", @click="reloadData") 查詢



    .col-lg-12
      .card-body.py-2
        p 符合條件資料 共 {{ totalRows }} 篇稿件
          span
            b
              small , 注意: 非今日觀看數為延遲統計數資料
        //- ul.list-unstyled 
          li 單位中心：即時中心 / 要聞中心 / 即時中心
          li 新聞分類：全選  
          li 平均單篇PV 1,XXX
          li 稿件來源：自製稿

    .col-lg-12
      .card
        .card-body
          .row
            .col-lg-4
              .form-group.mb-3
                label 區間條件篩選稿件數量
                  small (已上架)
                br
                input.form-control(
                  :value="$root.common.formatNumber(search_count,0)", 
                  type="text", 
                  disabled
                )
            .col-lg-4
              .form-group.mb-3
                label 區間條件篩選觀看總數
                br
                input.form-control(
                  :value="$root.common.formatNumber(search_views,0)",
                  type="text",
                  disabled
                )

      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 新聞數據列表
            b-button.width-md(variant="primary" @click="exportAnalysisData" )
              span 匯出報表  

          //- Table
          .table-responsive.mb-0
            b-table(
              responsive, 
              ref="NewsAnalysisList",
              :items="searchArticleList", 
              :fields="fields", 
              :per-page="perPage", 
              :current-page="currentPage", 
            )

              //- 排名
              template(v-slot:cell(index)="row") {{ row.index + 1 }}

              //- 新聞標題
              template(v-slot:cell(title)="row")
                span.text-left {{ row.item.title }}

              //- 分類
              template(v-slot:cell(type)="row") 
                span(v-if="row.item.main_type.father") {{ row.item.main_type.father.name }}
                span(v-if="row.item.main_type.name")  / {{ row.item.main_type.name }}

              //- Page View(PV)
              template(v-slot:cell(today_pv)="row") 
                span(v-if="statusTab == 0") {{ $root.common.formatNumber(row.item.today_pv, 0) }}
                span(v-if="statusTab == 1") {{ $root.common.formatNumber(row.item['7day_pv'], 0) }}
                span(v-if="statusTab == 2") {{ $root.common.formatNumber(row.item['14day_pv'], 0) }}
                span(v-if="statusTab == 3") {{ $root.common.formatNumber(row.item['30day_pv'], 0) }}

              //- Page View(PV)
              template(v-slot:cell(total_pv)="row") 
                span(v-if="row.item.total_pv > 0") {{ $root.common.formatNumber(row.item.total_pv, 0) }}
                span(v-else) -

              //- PV佔比
              template(v-slot:cell(percentage)="row") {{ row.item.percentage }}%

              //- 上稿人員
              template(v-slot:cell(editer)="row") {{ row.item.editer.name }}

              //- 上稿人員 - 多個
              template(v-slot:cell(editers)="row") 
                span(v-for="( editer, index ) in row.item.editers")
                  span(:key="index") {{ editer.name }}
                  span(v-if="index < row.item.editers.length - 1") , 

              //- 愛心數
              template(v-slot:cell(like)="row") 
                span(v-if="row.item.like") {{ row.item.like }}
                span(v-else) -

              //- 收藏數
              template(v-slot:cell(favorite)="row") 
                span(v-if="row.item.favorite") {{ row.item.favorite }}
                span(v-else) -

              //- 延伸閱讀點擊數
              template(v-slot:cell(extended-reading-clicks)="row") 
                span(v-if="!row.item.extending.length") -
                b-button.table-btn(
                  v-else
                  variant="primary" 
                  v-b-modal.news-detail
                  @click="clickShow('延伸閱讀', row.item.extending)") {{ $root.common.formatNumber(row.item["extended-reading-clicks"], 0) }}


              //- 延伸閱讀點擊率
              template(v-slot:cell(extended-reading-clicks-rate)="row") 
                span(v-if="row.item.extended_reading_clicks_rate") {{ row.item.extended_reading_clicks_rate }}%
                span(v-else) -

              //- 相關報導點擊數
              template(v-slot:cell(related-reports-clicks)="row")
                span(v-if="!row.item.related.length") -
                b-button.table-btn(
                  v-else
                  variant="primary" 
                  v-b-modal.news-detail
                  @click="clickShow('相關報導', row.item.related)") {{ $root.common.formatNumber(row.item["related-reports-clicks"], 0) }}

              //- 相關報導點擊率
              template(v-slot:cell(related-reports-clicks-rate)="row") 
                span(v-if="row.item.related_reports_clicks_rate") {{ row.item.related_reports_clicks_rate }}%
                span(v-else) -

              //- 稿件來源
              template(v-slot:cell(source)="row")
                span(v-for="( source ) in articleSource")
                  span(:key="source.id", v-if="source.id === row.item.source") {{ source.name }}

              //- 稿號
              template(v-slot:cell(code)="row") {{ row.item.code }}

              //- 出稿日期
              template(v-slot:cell(submission-date)="row") {{ row.item.post_at }}

              //- 更稿日期
              template(v-slot:cell(release-date)="row") {{ row.item.release_at }}
              
              //- 單位
              template(v-slot:cell(unit)="row") {{ row.item.unit }}

          .row.mb-md-2
            .col-12
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import TimePeriodTab from "./components/TimePeriodTab"
import NewsDetailModal from "./components/NewsDetailModal";


/**
 * Starter component
 */
export default {
  name: 'NewsAnalysis',
  data () {
    return {
      title: "新聞數據查詢 / 數據分析",
      items: [
        {
          text: "數據分析",
          href: "/dashboard",
        },
        {
          text: "新聞數據查詢",
          active: true,
        }
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      fields: [
        { key: 'index', label: "項次", class: 'width-auto center' },
        { key: "code", label: "稿號", class: 'width-auto center' },
        { key: 'title', label: "新聞標題", class: 'width-auto' },
        { key: "today_pv", label: "區間觀看數", class: 'width-auto center' },
        { key: "total_pv", label: "全部觀看數", class: 'width-auto center' },
        { key: "submission-date", label: "出稿日期", class: 'width-auto center' },
        { key: "release-date", label: "更稿日期", class: 'width-auto center' },
        { key: "source", label: "稿件來源", class: 'width-auto center' },
        { key: "type", label: "分類", class: 'width-auto center' },
        { key: "editer_name", label: "稿件作者", class: 'width-auto' },
        { key: "editer", label: "最後編輯人員", class: 'width-auto' },
        { key: "editers", label: "3 位編輯人員", class: 'width-auto' },
        { key: "like", label: "愛心數", class: 'width-auto center' },
        { key: "favorite", label: "收藏數", class: 'width-auto center' },
        // { key: "percentage", label: "PV 佔比", class: 'width-auto center' },
        // { key: "extended-reading-clicks", label: "延伸閱讀點擊數", class: 'width-auto center' },
        // { key: "extended-reading-clicks-rate", label: "延伸閱讀點擊率", class: 'width-auto center' },
        // { key: "related-reports-clicks", label: "相關報導點擊數", class: 'width-auto center' },
        // { key: "related-reports-clicks-rate", label: "相關報導點擊率", class: 'width-auto center' },
        // { key: "unit", label: "單位", class: 'width-auto center' }
      ],
      statusTab: 1,
      options: [
        { id: 0, name: "全部" },
        { id: 1, name: " 未處理" },
        { id: 2, name: "處理中" },
        { id: 3, name: "已結案" }
      ],
      articleTypes: [],
      articleSource: [
        { id: 0, name: '全部' },
        { id: 1, name: '後台' },
        { id: 2, name: 'RSS' },
        { id: 3, name: '網友投稿' },
        { id: 4, name: '網友爆料' },
        { id: 5, name: 'AI寫稿' }
      ],
      searchData: {
        unit: [],
        type: [],
        source: [],
        editer: ''
      },
      editer: [],
      currentEditer: false,
      currentEditerName: '',
      currentModalName: "",
      currentContent: [],
      showContentModal: false,
      search_article_type: '0',
      search_article_source: '1',
      article_count: 0,
      article_views: 0,
      type_counts: [],
      search_count: 0,
      search_views: 0,
      search_start_date: '',
      search_end_date: '',
      searchOtherEditerPermissionStatus: true
    };
  },
  async created () {
    await this.getNewsTypeList()
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      console.log('--> 變更篩選項目:', _status);
      this.statusTab = _status
      this.currentPage = 1
      this.reloadData()
    },
    async reloadData () {
      // 處理條件
      await this.processSearchUser()
      // 重新處理文章搜尋
      await this.$refs.NewsAnalysisList.refresh()
    },
    changeArticleTypeOption (_event) {

    },
    changearAicleSourceOption (_event) {

    },
    async getNewsTypeList () {
      let vm = this
      this.articleTypes = [{ id: 0, name: '全部' }]
      await this.$root.apis.getNewsTypeList(async function (_response) {
        let types = _response.body.data
        await types.forEach(async function (_type) {
          if (_type.on_dashboard) {
            await vm.articleTypes.push({
              id: _type.id,
              name: _type.name,
            })
          }
        })
        await this.searchArticleList()
      })
    },
    // 處理條件
    processSearchUser () {
      // 處理條件
      let searchOtherEditerPermissionStatus = this.$root.user.permission.indexOf('DCENTER_QUERY_OTHER_EDITER') > -1
      this.searchOtherEditerPermissionStatus = searchOtherEditerPermissionStatus
      console.log('--> 具有搜尋他人稿件權限:', searchOtherEditerPermissionStatus)

      if (!searchOtherEditerPermissionStatus) {
        console.log('--> 判定為僅限個人文章篩選')
        let name = (this.$root.user.editor_name) ? this.$root.user.editor_name : this.$root.user.name
        this.currentEditer = {
          id: this.$root.user.id,
          name: name
        }
      }
    },
    // 獲得推播項目清單
    searchArticleList (_table, _callback) {
      let vm = this

      // 處理條件
      this.processSearchUser()

      // 處理全站區間統計資料 
      if (this.$root.user.permission.indexOf('DCENTER_TOTAL_SITE_ANALYSIS') > -1) {
        this.getArticleRegenCount()
      }

      // 處理分類區間統計資料
      this.type_counts = []
      if (this.$root.user.permission.indexOf('DCENTER_TYPE_ARTICLE_ANALYSIS') > -1) {
        this.articleTypes.forEach(function (_type) {
          vm.getArticleRegenCount(_type.id)
        })
      }

      let searchParams = {
        search_source: 3,
        main_type: vm.search_article_type,
        source_type: vm.search_article_source,
        review_status: 0,
        sort: 1,
        range_type: (vm.statusTab + 1),
        range_start_date: vm.search_start_date,
        range_end_date: vm.search_end_date,
        format_type: 'analysis',
        edit_uid: (vm.currentEditer.id) ? vm.currentEditer.id : '',
        editer_name: (vm.currentEditerName) ? vm.currentEditerName : '',
        page: vm.currentPage
      }

      // 處理新聞資料撈取作業
      this.$root.apis.getNewsListByEditor(searchParams, function (_response) {
        let response = _response.body.data
        vm.currentPage = response.page
        vm.totalRows = response.total
        vm.perPage = response.per_page
        _callback(response.news)
      })

      // 處理新聞觀看數統計作業
      searchParams.search_source = 4
      this.$root.apis.getNewsListByEditor(searchParams, function (_response) {
        let response = _response.body.data
        vm.search_count = response.total
        vm.search_views = (response.views) ? response.views : 0
      })
    },
    // 請求匯出檔案
    exportAnalysisData () {
      let vm = this

      // 處理條件
      this.processSearchUser()
      let searchParams = {
        search_source: 3,
        main_type: vm.search_article_type,
        source_type: vm.search_article_source,
        review_status: 0,
        sort: 1,
        range_type: (vm.statusTab + 1),
        range_start_date: vm.search_start_date,
        range_end_date: vm.search_end_date,
        format_type: 'analysis',
        edit_uid: (vm.currentEditer.id) ? vm.currentEditer.id : '',
        editer_name: (vm.currentEditerName) ? vm.currentEditerName : '',
        page: vm.currentPage
      }

      let exportUri = this.$root.dashboard_uri + '/export/news/analysis' + '?' + this.$root.common.getQueryString(searchParams)
      console.log('--> 匯出檔案連結:', exportUri)
      window.open(exportUri, '_self')
    },
    async getArticleRegenCount (_searchArticleType = 0) {
      if (this.statusTab == 5 && (!this.search_start_date || !this.search_end_date)) {
        return
      }

      let vm = this
      // 重置資訊
      this.article_count = 0
      // 請求獲得新的資料
      await this.$root.apis.getNewsListByEditor({
        search_source: 4,
        main_type: _searchArticleType,
        range_start_date: vm.search_start_date,
        range_end_date: vm.search_end_date,
        format_type: 'analysis',
        range_type: (vm.statusTab + 1),
        range_start_date: vm.search_start_date,
        range_end_date: vm.search_end_date,
        page: 1,
        per_page: 1
      }, async function (_response) {
        let response = _response.body.data
        // 處理中心模式
        if (_searchArticleType) {
          let searchKey = false
          await vm.type_counts.forEach(function (_typeItem, _typeIndex) {
            if (_typeItem.id === _searchArticleType) {
              searchKey = true
              vm.type_counts[_typeIndex] = {
                type_id: _searchArticleType,
                article_count: response.total,
                article_views: (response.views) ? response.views : 0
              }
            }
          })

          if (!searchKey) {
            await vm.type_counts.push({
              type_id: _searchArticleType,
              article_count: response.total,
              article_views: (response.views) ? response.views : 0
            })
          }
        }
        // 處理一般模式
        else {
          vm.article_count = response.total
          vm.article_views = (response.views) ? response.views : 0
        }
      })
    },
    searchEditerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.editer = _response.body.data.items
      })
    },
    async clickShow (_modalName, _content) {
      console.log(_modalName, _content)
      this.currentModalName = await _modalName
      this.currentContent = await (_content.length > 0) ? _content : []
      await console.log('本次帶入的資料:', this.currentContent)
      this.showContentModal = await true
    },
    setStartDateChange (_date) {
      this.search_start_date = _date
      this.reloadData()
    },
    setEndDateChange (_date) {
      this.search_end_date = _date
      this.reloadData()
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    TimePeriodTab,
    NewsDetailModal
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #ffffff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  font-size: 14px
  &.delete
    color: #333333
  
</style>
