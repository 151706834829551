<template lang="pug">
div.article-editor-view
  .pt-1.pb-1.pl-1
    b-button(block,variant="primary",size="md",v-b-modal.mediaLibrary,@click="insertImageOnCurrentCursor()") 點我開啟圖庫
  div.ckeditor-view(v-if="ckeditor_uri")
    iframe(
      id="ckeditor-iframe" 
      :src="ckeditor_uri"
      @load="onIframeLoad"
    )
    b-modal(
      id="mediaLibrary",
      size="lg",
      title="圖庫系統",
      title-class="font-18",
      hide-footer
    )
      //- 圖片上傳區域
      div.modal-body
        p 圖片上傳
        b-form-file(
          @change="onFileChange"
        )

        .loading-status(v-if="uploadFileStatus")
          b-spinner(type="grow" variant="primary")

      //- 圖片列表區域
      div.modal-body(v-if="!uploadFileStatus")
        p 圖片列表
        .input-group
          input.form-control(
            type='text' 
            placeholder="請輸入圖片檔名關鍵字" 
            aria-label="請輸入圖片檔名關鍵字" 
            aria-describedby='basic-addon2'
            v-model="search_data.name"
          )
          .input-group-append
            button.btn.btn-dark.waves-effect.waves-light(type="button",@click="getImageFileList") 搜尋

        //- 圖片搜尋與清單
        .img-list
          //- 圖片列表
          .image-list-container.pt-2.pb-3.row
            .loading-status(v-if="loadImageStatus")
              b-spinner(type="grow" variant="primary")
            .col-lg-2.p-2(v-for="(file,index) in file_list", :key="index")
              img.image-library-item(
                :src="file.url" 
                @click="insertImageOnCurrentCursor(file.url)"
              )       
              .image-name {{ file.file_name }}    

          //- 分頁區域
          div.dataTables_paginate.paging_simple_numbers.float-right(v-if="totalRows > 0")
            ul.pagination.pagination-rounded.mb-0
              b-pagination(v-model="search_data.page", :total-rows="totalRows", :per-page="perPage")

</template>

<script>
export default {
  name: 'CkeditorView',
  props: ['content'],
  data () {
    return {
      iframe: false,
      lockInitStatus: false,
      ckeditor_uri: '',
      search_data: {
        name: '',
        type: 1,
        source: 0,
        sort_by: 1,
        sort_method: 'DESC',
        page: 1,
        per_page: 24
      },
      loadImageStatus: false,
      uploadFileStatus: false,
      file_list: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  watch: {
    'search_data.page' () {
      this.getImageFileList()
    },
  },
  created () {
    // 針對開發環境設置不同的編輯器來源
    if (this.$root.platform === 'dev') {
      this.ckeditor_uri = 'https://ckeditor.jbravo.dev/ckeditor.html'
    }
    // 針對所有環境設置不同的編輯器來源
    else {
      this.ckeditor_uri = this.$root.ckeditor_uri + '/ckeditor.html'
    }
    console.log('--> [編輯器父元件] 編輯器來源:', this.ckeditor_uri)
    // 初始話接收事件作業
    window.addEventListener("message", this.receiveMessage, false);
  },
  methods: {
    // 當 iframe 完全載入時調用這個方法
    onIframeLoad () {
      console.log('--> [編輯器父元件] 執行 iframe 的初始化操作...');
      this.iframe = document.getElementById('ckeditor-iframe').contentWindow;
      this.sendContentToIframe();
    },
    sendContentToIframe (_data) {
      // 獲取 iframe 的實際源
      const iframeOrigin = new URL(this.ckeditor_uri).origin;
      console.log('--> [編輯器父元件] 請求送出 setContent 事件:', this.content);
      this.iframe.postMessage({ type: 'setContent', data: this.content }, iframeOrigin);
    },
    receiveMessage (_event) {
      console.log('--> [編輯器父元件] 接收到裡面的資料:', _event);
      if (!this.lockInitStatus && _event.data.type === 'initializeEditorStart') {
        this.lockInitStatus = true
        console.log('--> [編輯器父元件] 編輯器回報開始進行載入作業:', _event.data.data)
        this.sendContentToIframe();
      }
      if (_event.data.type === 'editorContentChanged') {
        console.log('');
        console.log('-------------->');
        console.log('--> [編輯器父元件] 請求新聞編輯頁設置修改的稿件內容:', _event.data.data)
        this.$emit('setcontent', _event.data.data)
      }
    },
    insertImageOnCurrentCursor (_imageUri = "") {
      // 獲取 iframe 的實際源
      const iframeOrigin = new URL(this.ckeditor_uri).origin;
      console.log('--> [編輯器父元件] 請求在目前游標位置插入圖片:', _imageUri);

      try {
        this.iframe.postMessage({ type: 'insertImageOnCurrentCursor', data: _imageUri }, iframeOrigin);
      } catch (error) {
        console.log('--> [編輯器父元件] 插入圖片失敗:', error);
      }

      // 關閉圖庫元件
      this.$bvModal.hide('mediaLibrary')
    },
    // 獲得檔案列表
    getImageFileList () {
      this.file_list = []
      this.loadImageStatus = true
      this.$root.apis.getFileList(this.search_data, this.getFileListSuccess)
    },
    getFileListSuccess (_response) {
      this.file_list = _response.body.files
      this.totalRows = _response.body.total
      this.currentPage = _response.body.page
      this.perPage = _response.body.per_page
      this.loadImageStatus = false
    },
    // 接收到檔案上傳事件
    onFileChange (_event) {
      console.log('--> [編輯器父元件] 接收到檔案上傳事件:', _event);
      // 圖片上傳網址
      let upload_uri = this.$root.upload_uri + "/media/create/files/default"

      // 獲取選擇的檔案
      let file = _event.target.files[0];
      if (!file) return;

      // 創建 FormData 對象
      let formData = new FormData();
      formData.append('files[]', file);

      // 使用 axios 或 fetch 發送上傳請求
      this.uploadFileStatus = true
      fetch(upload_uri, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + this.$root.common.getCookie('api-token')
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('--> [編輯器父元件] 上傳成功:', data);
          this.uploadFileSuccess(data);
        })
        .catch(error => {
          console.error('--> [編輯器父元件] 上傳失敗:', error);
        });
    },
    uploadFileSuccess (_response) {
      this.uploadFileStatus = false
      console.log('--> [編輯器父元件] 上傳檔案成功:', _response);
      // 在這裡可以處理上傳成功後的邏輯
      if (_response.code === '0000') {
        this.insertImageOnCurrentCursor(_response.data[0].url)
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener("message", this.receiveMessage);
  }
};
</script>

<style scoped>
.ckeditor-view iframe {
  border: 0px;
  min-height: 800px;
  width: 100%;
}

img.image-library-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}

.img-list .image-name {
  height: 20px;
  width: 100%;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.loading-status {
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding-top: 33%;
  padding-bottom: 33%;
}
</style>
