import Vue from 'vue'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
import { data } from 'jquery'
Vue.use(VueResource)
Vue.use(VueCookies)

var vm, project
// ================
Vue.http.options.root = process.env.VUE_APP_DAPI_URL + "/"
Vue.http.interceptors.push(function (_request, _next) {
    Vue.http.headers.common['Authorization'] = 'Bearer ' + Vue.$cookies.get('api-token')
    Vue.http.headers.common['Content-Type'] = 'application/json'

    // 處理一般程序
    _next(function (_response) {
        var data = _response.body
        // console.log('執行錯誤檢查: ' + data.error_code)
        if (data) {
            switch (data.code) {
                case "1080":
                    // 彈出警告視窗
                    project.common.blockAction()
                    break
                default:
                    break
            }
        }
    })
})

export default class Apis {
    constructor(_vm) {
        vm = this
        project = _vm
    }

    /**
     * 請求確認目前裝置 Token 的狀態
     * @param {*} _success 
     * @param {*} _error 
     * @returns 
     */
    async confirmToken (_success, _error) {
        if (!project.auth_token) {
            return this.issueToken()
        }

        this.getTokenDetail(_success, function (_response) {
            // 准予可以自行更換 Token 的錯誤
            if (["1078", "1079"].indexOf(_response.body.code) > -1) {
                vm.issueToken()
            }
        })
    }

    /**
     * 請求獲取新的 Token
     * @param {*} _success 
     * @param {*} _error 
     */
    async issueToken (_success, _error) {
        await Vue.http.get("token").then(function (_response) {
            console.log("=> 取得新的 Token: " + _response.body.data);
            project.common.setCookie("api-token", _response.body.data)
            vm.getTokenDetail()
        }, _error)
    }

    /**
     * 取得目前 Token 相關資訊
     * @param {*} _success 
     * @param {*} _error 
     */
    async getTokenDetail (_success, _error) {
        await Vue.http.get("token/detail").then(async function (_response) {
            let response = _response.body.data

            // 如果已經登入
            if (response.bind) {
                await vm.getMemberInfo()
            }

            // 如果 Token 被綁定中, 且並未遭到封鎖
            project.login_status = await (!response.block && response.bind) ? true : false
            // 設定後台系統啟用完成
            project.system_ready = await true
            // 延展 Api Token Cookie
            await project.common.extendApiToken()
        }, _error)
    }

    /**
     * 獲取登入的會員資料
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMemberInfo (_success, _error) {
        await Vue.http.get("member/status").then(function (_response) {
            project.user = _response.body.data

            if (project.needGoBackPage) {
                project.needGoBackPage = false
                // return project.goLastPage()
            }

            if (project.needAutoRedirect) {
                project.needAutoRedirect = false
                // return project.goPage('home')
            }

        }, _error)
    }

    /**
     * 向後端請求進行登入操作
     * @param {*} _form 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setMemberLogin (_form, _success, _error) {
        await Vue.http.post('member/login', _form).then(_success, _error)
    }

    /**
     * 向後端請求進行登出操作
     * @param {*} _success 
     * @param {*} _error 
     */
    async setMemberLogout (_success, _error) {
        await Vue.http.post('member/logout').then(_success, _error)
    }

    /**
     * 獲得新聞列表
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsListByEditor (_params, _success, _error) {
        Vue.http.get('news/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 獲得一篇新聞內容
     * @param {*} _NewsHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsDetail (_NewsHashID, _success, _error) {
        Vue.http.get('news/detail/' + _NewsHashID).then(_success, _error)
    }

    /**
     * 獲得新聞稿件關鍵字清單
     * @param {*} _NewsContent
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsKeywordByAi (_NewsContent, _success, _error) {
        Vue.http.post('news/keywords', { content: _NewsContent }).then(_success, _error)
    }

    /**
     * 獲得新聞稿件標題清單
     * @param {*} _NewsContent 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTitleByAi (_NewsContent, _success, _error) {
        Vue.http.post('news/titles', { content: _NewsContent }).then(_success, _error)
    }

    /**
     * 獲得新聞分類清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypeList (_success, _error) {
        Vue.http.get('news/news_type/all').then(_success, _error)
    }

    /**
     * 快速取得標籤清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFastTagList (_params, _success, _error) {
        Vue.http.get('select/tags', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 快速取得管理員清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFastManagerList (_params, _success, _error) {
        Vue.http.get('select/managers', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 快速取得新聞清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFastNewsList (_params, _success, _error) {
        Vue.http.get('select/news', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 快速取得播放清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFastVideoList (_params, _success, _error) {
        Vue.http.get('select/videos', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 取得圖庫項目列表
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFileList (_params, _success, _error) {
        Vue.http.get('files/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求更新檔案資訊
     * @param {*} _fileId 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putFileDetail (_fileId, _data, _success, _error) {
        await Vue.http.put('files/edit/' + _fileId, _data).then(_success, _error)
    }

    /**
     * 獲得新聞內頁的公告顯示項目
     * @param {*} _type 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getArticleBoardDetail (_type = 1, _success, _error) {
        Vue.http.get('board/dashboard/current', {
            params: {
                _type: 1
            }
        }).then(_success, _error)
    }

    /**
     * 請求建立一篇新文章
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createNewArticle (_data, _success, _error) {
        Vue.http.post('news/create', _data).then(_success, _error)
    }

    /**
     * 請求更新一篇文章
     * @param {*} _articleId 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putArticleDetail (_articleId, _data, _success, _error) {
        Vue.http.put('news/edit/' + _articleId, _data).then(_success, _error)
    }

    /**
     * 請求送審一篇文章
     * @param {*} _articleId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async submitArticle (_articleId, _success, _error) {
        Vue.http.post('news/submit/' + _articleId, _success, _error).then(_success, _error)
    }

    /**
     * 請求退回一篇文章
     * @param {*} _articleId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async returnArticle (_articleId, _success, _error) {
        Vue.http.post('news/return/' + _articleId, _success, _error).then(_success, _error)
    }

    /**
     * 請求上架一篇文章
     * @param {*} _articleId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setArticleOnline (_articleId, _success, _error) {
        Vue.http.post('news/online/' + _articleId, _success, _error).then(_success, _error)
    }

    /**
     * 請求下架一篇文章
     * @param {*} _articleId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setArticleOffline (_articleId, _success, _error) {
        Vue.http.post('news/offline/' + _articleId, _success, _error).then(_success, _error)
    }








    /**
     * 獲取目前區域設定
     * @param {*} _type 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getAreaSetting (_type, _success, _error) {
        Vue.http.get('marketing/home/region/' + _type + '/detail').then(_success, _error)
    }

    /**
     * 更新指定區域的項目資料
     * @param {*} _regionType 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async saveAreaSetting (_regionType, _data, _success, _error) {
        Vue.http.put('marketing/home/region/' + _regionType + '/detail', _data).then(_success, _error)
    }

    /**
     * 發布指定區域的項目資料
     * @param {*} _regionType 
     * @param {*} _success 
     * @param {*} _error 
     */
    async releaseAreaSetting (_regionType, _success, _error) {
        Vue.http.post('marketing/home/region/' + _regionType + '/detail').then(_success, _error)
    }

    /**
     * 取得分區內有 TAB 頁籤的清單
     * @param {*} _regionSource
     * @param {*} _success 
     * @param {*} _error 
     */
    async getAreaTabList (_regionSource, _success, _error) {
        Vue.http.get('marketing/home/region/' + _regionSource + '/list').then(_success, _error)
    }


    /**
     * 獲取三宮格區清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getThreeAreaList (_success, _error) {
        Vue.http.get('marketing/home/region/other/8/list').then(_success, _error)
    }

    /**
     * 新增一個三宮格區塊
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createThreeArea (_data, _success, _error) {
        Vue.http.post('marketing/home/region/other/8/create', _data).then(_success, _error)
    }

    /**
     * 刪除一個三宮格區塊
     * @param {*} _regionHashId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async cancelThreeArea (_regionHashId, _success, _error) {
        Vue.http.delete('marketing/home/region/other/8/' + _regionHashId).then(_success, _error)
    }

    /**
     * 獲取三宮格區域設定
     * @param {*} _regionHashId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getThreeAreaSetting (_regionHashId, _success, _error) {
        Vue.http.get('marketing/home/region/other/8/' + _regionHashId).then(_success, _error)
    }

    /**
     * 更新三宮格區域的項目資料
     * @param {*} _regionHashId 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async saveThreeAreaSetting (_regionHashId, _data, _success, _error) {
        Vue.http.put('marketing/home/region/other/8/' + _regionHashId, _data).then(_success, _error)
    }

    /**
     * 發布三宮格區域的項目資料
     * @param {*} _regionHashId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async releaseThreeAreaSetting (_regionHashId, _success, _error) {
        Vue.http.post('marketing/home/region/other/8/' + _regionHashId).then(_success, _error)
    }

    /**
     * 設定 3 宮格區域的排序
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async changeThreeAreaSort (_data, _success, _error) {
        Vue.http.post('marketing/home/region/other/8/setting/sort', _data).then(_success, _error)
    }


    /**
     * 取得人員列表
     * @param {*} _search
     * @param {*} _success 
     * @param {*} _error 
     */
    async getManagersList (_search, _success, _error) {
        Vue.http.get('select/managers', {
            params: _search
        }).then(_success, _error)
    }

    /**
     * 獲得目前的新聞分類頁列表
     * @param {*} _search
     * @param {*} _success 
     * @param {*} _error 
     */
    async getTypePageList (_search, _success, _error) {
        Vue.http.get('marketing/types-page/list', {
            params: _search
        }).then(_success, _error)
    }

    /**
     * 建立單一新聞分類頁設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async createNewsTypePage (_success, _error) {
        Vue.http.post('marketing/types-page/create').then(_success, _error)
    }

    /**
     * 取得單一新聞分類頁設定
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypePage (_newsTypePageHashID, _success, _error) {
        Vue.http.get('marketing/types-page/' + _newsTypePageHashID + '/setting').then(_success, _error)
    }

    /**
     * 取得分類頁目前已設定的新聞列表清單
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypePageNewsList (_newsTypePageHashID, _success, _error) {
        Vue.http.get('marketing/types-page/' + _newsTypePageHashID + '/news-list').then(_success, _error)
    }

    /**
     * 更新單一新聞分類頁設定
     * @param {*} _newsTypePageHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateNewsTypePage (_newsTypePageHashID, _data, _success, _error) {
        Vue.http.put('marketing/types-page/' + _newsTypePageHashID + '/setting', _data).then(_success, _error)
    }

    /**
     * 發布單一新聞分類頁設定
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async releaseNewsTypePage (_newsTypePageHashID, _success, _error) {
        Vue.http.post('marketing/types-page/' + _newsTypePageHashID + '/release').then(_success, _error)
    }

    /**
    * 刪除單一新聞分類頁設定
    * @param {*} _newsTypePageHashID
    * @param {*} _success 
    * @param {*} _error 
    */
    async deleteNewsTypePage (_newsTypePageHashID, _success, _error) {
        Vue.http.delete('marketing/types-page/' + _newsTypePageHashID + '/setting').then(_success, _error)
    }

    /**
     * 獲取分類頁目前區域設定
     * @param {*} _typeHashID
     * @param {*} _regionType
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypePageRegionSetting (_typeHashID, _regionType, _success, _error) {
        Vue.http.get(`marketing/types-page/region/${_typeHashID}/${_regionType}`).then(_success, _error)
    }

    /**
     * 暫存分類頁目前區域設定
     * @param {*} _typeHashID
     * @param {*} _regionType
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async saveNewsTypePageRegionSetting (_typeHashID, _regionType, _data, _success, _error) {
        Vue.http.put(`marketing/types-page/region/${_typeHashID}/${_regionType}`, _data).then(_success, _error)
    }

    /**
     * 獲取指定新聞列表項目內容與版面配置
     * @param {*} _newsTypePageHashID
     * @param {*} _regionHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypeLayoutAndContentSetting (_newsTypePageHashID, _regionHashID, _success, _error) {
        Vue.http.get(`marketing/types-page/multiple/${_newsTypePageHashID}/${_regionHashID}/detail`).then(_success, _error)
    }

    /**
     * 請求建立一個新的新聞列表項目
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async createNewsTypePageNewsSetting (_newsTypePageHashID, _success, _error) {
        Vue.http.post(`marketing/types-page/multiple/${_newsTypePageHashID}/create`).then(_success, _error)
    }

    /**
     * 更新指定新聞列表版面配置
     * @param {*} _newsTypePageHashID
     * @param {*} _regionHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateNewsTypeLayoutSetting (_newsTypePageHashID, _regionHashID, _data, _success, _error) {
        Vue.http.put(`marketing/types-page/multiple/${_newsTypePageHashID}/${_regionHashID}/setting`, _data).then(_success, _error)
    }

    /**
     * 更新指定新聞列表項目內容配置
     * @param {*} _newsTypePageHashID
     * @param {*} _regionHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateNewsTypeContentSetting (_newsTypePageHashID, _regionHashID, _data, _success, _error) {
        Vue.http.put(`marketing/types-page/multiple/${_newsTypePageHashID}/${_regionHashID}/content`, _data).then(_success, _error)
    }

    /**
     * 移除指定新聞列表項目
     * @param {*} _newsTypePageHashID
     * @param {*} _regionHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async removeNewsTypeLayoutAndContentSetting (_newsTypePageHashID, _regionHashID, _success, _error) {
        Vue.http.delete(`marketing/types-page/multiple/${_newsTypePageHashID}/${_regionHashID}/remove`).then(_success, _error)
    }


    /**
     * 獲得新聞分類第一層列表
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypeLevel01List (_success, _error) {
        Vue.http.get('news/news_type/list').then(_success, _error)
    }

    /**
     * 獲得新聞分類第二層列表
     * @param {*} _newsTypeHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getNewsTypeLevel02List (_newsTypeHashID, _success, _error) {
        Vue.http.get(`news/news_type/${_newsTypeHashID}/list`).then(_success, _error)
    }

    /**
     * 新增一個主分類
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async createMainCategory (_data, _success, _error) {
        Vue.http.post('news/news_type/create', _data).then(_success, _error)
    }

    /**
     * 設定主分類排序
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async sortMainCategory (_data, _success, _error) {
        Vue.http.post('news/news_type/sort', _data).then(_success, _error)
    }

    /**
     * 儲存子分類
     * @param {*} _newsTypeHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async putSubTypeCategory (_newsTypeHashID, _data, _success, _error) {
        Vue.http.put(`news/news_type/${_newsTypeHashID}`, _data).then(_success, _error)
    }

    /**
     * 獲得目前的模組項目列表
     * @param {*} _search
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMarketingNewsTypeList (_search, _success, _error) {
        Vue.http.get('marketing/news-type/list', {
            params: _search
        }).then(_success, _error)
    }

    /**
     * 新增單一模組項目設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async createPlacementSetting (_success, _error) {
        Vue.http.post('marketing/news-type/create').then(_success, _error)
    }

    /**
     * 取得單一模組項目設定
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getPlacementSetting (_newsTypePageHashID, _success, _error) {
        Vue.http.get(`marketing/news-type/${_newsTypePageHashID}/setting`).then(_success, _error)
    }

    /**
     * 更新單一模組項目設定
     * @param {*} _newsTypePageHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updatePlacementSetting (_newsTypePageHashID, _data, _success, _error) {
        Vue.http.put(`marketing/news-type/${_newsTypePageHashID}/setting`, _data).then(_success, _error)
    }

    /**
     * 發布單一模組項目設定
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async releasePlacementSetting (_newsTypePageHashID, _success, _error) {
        Vue.http.post(`marketing/news-type/${_newsTypePageHashID}/release`).then(_success, _error)
    }

    /**
     * 移除單一模組項目設定
     * @param {*} _newsTypePageHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async removePlacementSetting (_newsTypePageHashID, _success, _error) {
        Vue.http.delete(`marketing/news-type/${_newsTypePageHashID}/setting`).then(_success, _error)
    }


    /**
     * 取得推播項目列表
     * @param {*} _statusType 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBroadcastList (_search, _success, _error) {
        Vue.http.get('broadcast/list', {
            params: _search
        }).then(_success, _error)
    }

    /**
     * 取得單一推播項目
     * @param {*} _broadcastItemId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBroadcastItem (_broadcastItemId, _success, _error) {
        Vue.http.get('broadcast/' + _broadcastItemId + '/detail').then(_success, _error)
    }

    /**
     * 建立推播項目
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createBroadcast (_data, _success, _error) {
        Vue.http.post('broadcast/create', _data).then(_success, _error)
    }

    /**
     * 更新推播項目
     * @param {*} _broadcastItemId 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putBroadcast (_broadcastItemId, _data, _success, _error) {
        Vue.http.put('broadcast/' + _broadcastItemId + '/update', _data).then(_success, _error)
    }

    /**
     * 取消推播項目
     * @param {*} _broadcastItemId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async cancelBroadcastItem (_broadcastItemId, _success, _error) {
        Vue.http.delete('broadcast/' + _broadcastItemId + '/cancel').then(_success, _error)
    }

    /**
     * 取得 Line 推播列表
     * @param {*} _search 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLineBroadcastList (_search, _success, _error) {
        Vue.http.get('broadcast-line/list', {
            params: _search
        }).then(_success, _error)
    }

    /**
     * 取得單一 Line 推播項目
     * @param {*} _broadcastItemId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLineBroadcastItem (_broadcastItemId, _success, _error) {
        Vue.http.get('broadcast-line/' + _broadcastItemId + '/detail').then(_success, _error)
    }

    /**
     * 建立 Line 推播項目
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createLineBroadcast (_data, _success, _error) {
        Vue.http.post('broadcast-line/create', _data).then(_success, _error)
    }

    /**
     * 更新 Line 推播項目
     * @param {*} _broadcastItemId 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putLineBroadcast (_broadcastItemId, _data, _success, _error) {
        Vue.http.put('broadcast-line/' + _broadcastItemId + '/update', _data).then(_success, _error)
    }

    /**
     * 取消推播項目
     * @param {*} _broadcastItemId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async cancelLineBroadcastItem (_broadcastItemId, _success, _error) {
        Vue.http.delete('broadcast-line/' + _broadcastItemId + '/cancel').then(_success, _error)
    }

    /**
     * 取得公告列表
     * @param {*} _search 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getAnnounceList (_search, _success, _error) {
        Vue.http.get('marketing/announcement/list?page=' + _search.page + '&type=' + _search.type + '&word=' + _search.word).then(_success, _error)
    }

    /**
     * 取得單一公告資訊
     * @param {*} _id 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getAnnounceDetail (_id, _success, _error) {
        Vue.http.get('marketing/announcement/' + _id + '/detail').then(_success, _error)
    }

    /**
    * 刪除單一公告資訊
    * @param {*} _id 
    * @param {*} _success 
    * @param {*} _error 
    */
    async deleteAnnounceDetail (_id, _success, _error) {
        Vue.http.delete('marketing/announcement/' + _id + '/detail').then(_success, _error)
    }

    /**
     * 新增單一公告
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createAnnounceDetail (_data, _success, _error) {
        Vue.http.post('marketing/announcement/create', _data).then(_success, _error)
    }

    /**
     * 更新單一公告
     * @param {*} _id 
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateAnnounceDetail (_id, _data, _success, _error) {
        Vue.http.put('marketing/announcement/' + _id + '/detail', _data).then(_success, _error)
    }

    /**
    * 發佈單一公告
    * @param {*} _id 
    * @param {*} _success 
    * @param {*} _error 
    */
    async releaseAnnounceDetail (_id, _success, _error) {
        Vue.http.post('marketing/announcement/' + _id + '/release').then(_success, _error)
    }

    /**
     * 獲得站點新聞標籤列表
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getSiteNewsTagList (_params, _success, _error) {
        Vue.http.get('tags/site/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求更新新聞標籤
     * @param {*} _TagHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putSiteNewsTag (_TagHashID, _data, _success, _error) {
        Vue.http.put('tags/site/detail/' + _TagHashID, _data).then(_success, _error)
    }

    /**
     * 請求建立影音標籤
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createSiteNewsTag (_data, _success, _error) {
        Vue.http.post('tags/site/create', _data).then(_success, _error)
    }

    /**
     * 獲得站點影音標籤列表
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getSiteVideoTagList (_params, _success, _error) {
        Vue.http.get('tags/video/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求更新影音標籤
     * @param {*} _TagHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putSiteVideoTag (_TagHashID, _data, _success, _error) {
        Vue.http.put('tags/video/detail/' + _TagHashID, _data).then(_success, _error)
    }

    /**
     * 請求建立影音標籤
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createSiteVideoTag (_data, _success, _error) {
        Vue.http.post('tags/video/create', _data).then(_success, _error)
    }

    async removeSiteTag (_type, _TagHashID, _success, _error) {
        Vue.http.delete('tags/' + _type + '/detail/' + _TagHashID).then(_success, _error)
    }

    /**
     * 請求獲得用戶清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMemberList (_params, _success, _error) {
        Vue.http.get('member/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求獲得單一用戶資訊
     * @param {*} _MemberHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMemberDetail (_MemberHashID, _success, _error) {
        Vue.http.get(`member/${_MemberHashID}`).then(_success, _error)
    }

    /**
     * 請求更新用戶資訊
     * @param {*} _MemberHashID 
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateMemberDetail (_MemberHashID, _data, _success, _error) {
        Vue.http.put(`member/${_MemberHashID}`, _data).then(_success, _error)
    }

    /**
     * 請求獲得單一用戶旺幣歷程
     * @param {*} _MemberHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMemberWanCoinList (_MemberHashID, _success, _error) {
        Vue.http.get(`member/${_MemberHashID}/wancoin-list`).then(_success, _error)
    }

    /**
     * 請求獲得單一用戶綁卡紀錄
     * @param {*} _MemberHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMemberBindCardList (_MemberHashID, _success, _error) {
        Vue.http.get(`member/${_MemberHashID}/bindcard-list`).then(_success, _error)
    }

    /**
     * 請求對用戶增減旺幣
     * @param {*} _MemberHashID 
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateMemberWanCoin (_MemberHashID, _data, _success, _error) {
        Vue.http.post(`member/${_MemberHashID}/wancoin`, _data).then(_success, _error)
    }

    /**
     * 取得全站旺幣資訊
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinInfo (_success, _error) {
        Vue.http.get('wancoin/site').then(_success, _error)
    }

    /**
     * 以旺幣區間篩選持有者用戶清單
     * @param {*} _params
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinMember (_params, _success, _error) {
        Vue.http.get('wancoin/members', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 獲得 RSS 項目匯入清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getRssImportList (_params, _success, _error) {
        Vue.http.get('rss/import/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 獲得 RSS 項目匯出清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getRssExportList (_params, _success, _error) {
        Vue.http.get('rss/export/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求更新 RSS Import 項目
     * @param {*} _itemID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putRssImportItem (_itemID, _data, _success, _error) {
        Vue.http.put('rss/import/' + _itemID + '/detail', _data).then(_success, _error)
    }

    /**
     * 請求更新 RSS Export 項目
     * @param {*} _itemID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putRssExportItem (_itemID, _data, _success, _error) {
        Vue.http.put('rss/export/' + _itemID + '/detail', _data).then(_success, _error)
    }

    /**
     * 請求移除 RSS Import 項目
     * @param {*} _itemID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async delRssImportItem (_itemID, _success, _error) {
        Vue.http.delete('rss/import/' + _itemID).then(_success, _error)
    }

    /**
     * 請求移除 RSS Export 項目
     * @param {*} _itemID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async delRssExportItem (_itemID, _success, _error) {
        Vue.http.delete('rss/export/' + _itemID).then(_success, _error)
    }

    /**
     * 請求建立 RSS Import 項目
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createRssImportItem (_data, _success, _error) {
        Vue.http.post('rss/import/create', _data).then(_success, _error)
    }

    /**
     * 請求建立 RSS Export 項目
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createRssExportItem (_data, _success, _error) {
        Vue.http.post('rss/export/create', _data).then(_success, _error)
    }

    /**
     * 請求獲得旺幣任務清單
     * @param {*} _params
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinQuests (_params, _success, _error) {
        Vue.http.get('wancoin/quests', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求獲得單一旺幣任務
     * @param {*} _QuestHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinQuestsById (_QuestHashID, _success, _error) {
        Vue.http.get(`wancoin/quest/${_QuestHashID}`).then(_success, _error)
    }

    /**
     * 請求新增旺幣任務
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async createWancoinQuests (_data, _success, _error) {
        Vue.http.post('wancoin/create', _data).then(_success, _error)
    }

    /**
     * 請求更新單一旺幣任務
     * @param {*} _QuestHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateWancoinQuests (_QuestHashID, _data, _success, _error) {
        Vue.http.put(`wancoin/quest/${_QuestHashID}`, _data).then(_success, _error)
    }

    /**
     * 請求獲得單一旺幣任務統計
     * @param {*} _QuestHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWanconinStatistical (_QuestHashID, _success, _error) {
        Vue.http.get(`wancoin/quest/${_QuestHashID}/statistical`).then(_success, _error)
    }

    /**
     * 請求獲得管理員帳號列表
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getManagerAccountList (_params, _success, _error) {
        Vue.http.get('manager/list', { params: _params }).then(_success, _error)
    }

    /** 請求獲得旺幣兌換項目清單
     * @param {*} _params
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinCouponList (_params, _success, _error) {
        Vue.http.get('coupon/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 請求獲得兌換項目的已兌換清單
     * @param {*} _promotionalItemHashID
     * @param {*} _params
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinCouponExchangeList (_promotionalItemHashID, _params, _success, _error) {
        Vue.http.get(`coupon/${_promotionalItemHashID}/item/statistics`, {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 上架單一旺幣兌換項目
     * @param {*} _promotionalItemHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async setupWancoinExchangeItem (_promotionalItemHashID, _success, _error) {
        Vue.http.post(`coupon/${_promotionalItemHashID}/set-up`).then(_success, _error)
    }

    /**
     * 下架單一旺幣兌換項目
     * @param {*} _promotionalItemHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async setdownWancoinExchangeItem (_promotionalItemHashID, _success, _error) {
        Vue.http.post(`coupon/${_promotionalItemHashID}/set-down`).then(_success, _error)
    }

    /**
     * 取得單一旺幣兌換項目
     * @param {*} _promotionalItemHashID
     * @param {*} _success 
     * @param {*} _error 
     */
    async getWancoinExchangeItem (_promotionalItemHashID, _success, _error) {
        Vue.http.get(`coupon/${_promotionalItemHashID}/item`).then(_success, _error)
    }

    /**
     * 請求更新旺幣兌換項目
     * @param {*} _promotionalItemHashID
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateWancoinExchangeItem (_promotionalItemHashID, _data, _success, _error) {
        Vue.http.put(`coupon/${_promotionalItemHashID}/item`, _data).then(_success, _error)
    }

    /**
     * 請求新增旺幣兌換項目
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async createWancoinExchangeItem (_data, _success, _error) {
        Vue.http.post('coupon/create', _data).then(_success, _error)
    }

    /**
     * 請求獲得單一管理員資料
     * @param {*} _managerID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getManagerDetail (_managerID, _success, _error) {
        Vue.http.get('manager/' + _managerID).then(_success, _error)
    }

    /**
     * 請求更新單一管理員資料
     * @param {*} _managerID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putManagerDetail (_managerID, _data, _success, _error) {
        Vue.http.put('manager/' + _managerID, _data).then(_success, _error)
    }

    /**
     * 取得管理員部門清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getManagerDepartmentsList (_success, _error) {
        Vue.http.get('manager/departments').then(_success, _error)
    }

    /**
     * 取得管理員部門職位清單
     * @param {*} _departmentsId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getManagerDepartmentsPositionsList (_departmentsId, _success, _error) {
        Vue.http.get('manager/' + _departmentsId + '/positions').then(_success, _error)
    }

    /**
     * 取得贊助訂單清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBillList (_params, _success, _error) {
        Vue.http.get('orders/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 取得單一贊助訂單
     * @param {*} _OrderHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getBillDetail (_OrderHashID, _success, _error) {
        Vue.http.get('orders/' + _OrderHashID + '/detail').then(_success, _error)
    }

    /**
     * 更新訂單的備註資訊
     * @param {*} _OrderHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setBillComment (_OrderHashID, _data, _success, _error) {
        Vue.http.post('orders/' + _OrderHashID + '/comment', _data).then(_success, _error)
    }

    /**
     * 請求訂單退款作業
     * @param {*} _OrderHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async returnBillPayment (_OrderHashID, _success, _error) {
        Vue.http.post('orders/' + _OrderHashID + '/return').then(_success, _error)
    }

    /**
     * 請求獲得升等任務清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getUpgradeTaskList (_params, _success, _error) {
        Vue.http.get('wancoin/badge/list', { params: _params }).then(_success, _error)
    }

    /**
     * 以帳號查詢升等任務紀錄
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getUpgradeTaskListByMember (_params, _success, _error) {
        Vue.http.get('wancoin/badge/by-member', { params: _params }).then(_success, _error)
    }




    // ============ 內部公告 ================
    /**
     * 取得內部公告列表
     * @param {*} _search 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getInternalAnnounceList (_search, _success, _error) {
        Vue.http.get('board/dashboard/list?page=' + _search.page).then(_success, _error)
    }

    /**
     * 取得單一內部公告資訊
     * @param {*} _id 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getInternalAnnounceDetail (_id, _success, _error) {
        Vue.http.get('board/dashboard/' + _id + '/detail').then(_success, _error)
    }

    /**
    * 刪除單一內部公告資訊
    * @param {*} _id 
    * @param {*} _success 
    * @param {*} _error 
    */
    async deleteInternalAnnounceDetail (_id, _success, _error) {
        Vue.http.delete('board/dashboard/' + _id + '/detail').then(_success, _error)
    }

    /**
     * 新增單一內部公告
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createInternalAnnounceDetail (_data, _success, _error) {
        Vue.http.post('board/dashboard/create', _data).then(_success, _error)
    }

    /**
     * 更新單一內部公告
     * @param {*} _id 
     * @param {*} _data
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateInternalAnnounceDetail (_id, _data, _success, _error) {
        Vue.http.put('board/dashboard/' + _id + '/detail', _data).then(_success, _error)
    }

    /**
    * 發佈單一內部公告
    * @param {*} _id 
    * @param {*} _success 
    * @param {*} _error 
    */
    async releaseInternalAnnounceDetail (_id, _success, _error) {
        Vue.http.post('board/dashboard/' + _id + '/release').then(_success, _error)
    }



    // ============ 直播管理－專區首頁編輯 ================

    /**
     *  取得大直播專區設定資料
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveConfig (_success, _error) {
        Vue.http.get('live/setting/region/live').then(_success, _error)
    }

    /**
    * 更新大直播影片設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateLiveVideoConfig (_data, _success, _error) {
        Vue.http.put('live/setting/livevideo', _data).then(_success, _error)
    }

    /**
    * 更新熱門直播設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateHotLiveConfig (_data, _success, _error) {
        Vue.http.put('live/setting/hotlive', _data).then(_success, _error)
    }

    /**
    * 更新特別企劃設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateSpecialPlanConfig (_data, _success, _error) {
        Vue.http.put('live/setting/specialplan', _data).then(_success, _error)
    }

    /**
    * 更新直播節目設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateLiveProgramConfig (_data, _success, _error) {
        Vue.http.put('live/setting/liveprogram', _data).then(_success, _error)
    }





    // ============ 直播管理－專區首頁編輯 ================
    /**
    * 取得播放清單列表
    * @param {*} _success 
    * @param {*} _error 
    */
    async getPlayList (data, _success, _error) {
        Vue.http.get('live/playlist/list?page=' + data.page + '&type=' + data.type + '&sort_type=' + data.sort_type).then(_success, _error)
    }


    /**
     * 新增播放清單
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createPlayList (_data, _success, _error) {
        Vue.http.post('live/playlist/create', _data).then(_success, _error)
    }

    /**
    * 取得單一播放清單資訊
    * @param {*} _success 
    * @param {*} _error 
    */
    async getPlayListDetail (_id, _success, _error) {
        Vue.http.get('live/playlist/detail/' + _id).then(_success, _error)
    }

    /**
    * 取得單一播放清單列表
    * @param {*} _success 
    * @param {*} _error 
    */
    async getPlayListVideo (data, _success, _error) {
        Vue.http.get('live/playlist/videos/' + data.id + '?sort_type=' + data.sort_type).then(_success, _error)
    }

    /**
    * 更新單一播放清單資訊
    * @param {*} _success 
    * @param {*} _error 
    */
    async updatePlayListDetail (_id, _data, _success, _error) {
        Vue.http.put('live/playlist/detail/' + _id, _data).then(_success, _error)
    }

    /**
    * 刪除單一播放清單資訊
    * @param {*} _success 
    * @param {*} _error 
    */
    async deletePlayListDetail (_id, _success, _error) {
        Vue.http.delete('live/playlist/detail/' + _id).then(_success, _error)
    }

    /**
     * 取得節目清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getProgramList (_params, _success, _error) {
        Vue.http.get('program/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 建立單一節目詳情
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createProgramDetail (_params, _success, _error) {
        Vue.http.post('program/create', _params).then(_success, _error)
    }

    /**
     * 更新單一節目詳情
     * @param {*} _id
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateProgramDetail (_id, _params, _success, _error) {
        Vue.http.put('program/' + _id, _params).then(_success, _error)
    }

    /**
     * 取得單一節目詳情
     * @param {*} _success 
     * @param {*} _error 
     */
    async getProgramDetail (_id, _success, _error) {
        Vue.http.get('program/' + _id).then(_success, _error)
    }

    /**
     * 刪除單一節目項目
     * @param {*} _success 
     * @param {*} _error 
     */
    async deleteProgram (_id, _success, _error) {
        Vue.http.delete('program/' + _id).then(_success, _error)
    }

    /**
     * 獲得直播節目表模組清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveProgramList (_params, _success, _error) {
        Vue.http.get('live/program/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 取得目前啟用的播放清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getCurrentLiveProgramList (_success, _error) {
        Vue.http.get('live/program/current').then(_success, _error)
    }

    /**
     * 啟用指定的節目播放清單
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setCurrentLiveProgramList (_ProgramHashID, _success, _error) {
        Vue.http.post('live/program/start/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 移除指定的節目播放清單
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async delLiveProgramList (_ProgramHashID, _success, _error) {
        Vue.http.delete('live/program/delete/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 請求複製一個直播節目表
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async copyLiveProgramList (_ProgramHashID, _success, _error) {
        Vue.http.post('live/program/copy/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 取得一個節目表的資料
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveProgramDetail (_ProgramHashID, _success, _error) {
        Vue.http.get('live/program/detail/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 請求更新單一節目表資料
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putLiveProgramDetail (_ProgramHashID, _data, _success, _error) {
        Vue.http.put('live/program/edit/' + _ProgramHashID, _data).then(_success, _error)
    }

    /**
     * 請求建立一個直播節目表
     * @param {*} _success 
     * @param {*} _error 
     */
    async addLiveProgramDetail (_success, _error) {
        Vue.http.post('live/program/create').then(_success, _error)
    }

    /**
     * 取得一個節目表的項目清單
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveProgramItemList (_ProgramHashID, _success, _error) {
        Vue.http.get('live/program/item/' + _ProgramHashID + '/list').then(_success, _error)
    }

    /**
     * 請求建立一個節目表項目
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async addProgramItem (_ProgramHashID, _data, _success, _error) {
        Vue.http.post('live/program/item/' + _ProgramHashID + '/create', _data).then(_success, _error)
    }

    /**
     * 請求更新一個節目表項目
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putProgramItem (_ProgramHashID, _LiveProgramItemHashID, _data, _success, _error) {
        Vue.http.put('live/program/item/' + _ProgramHashID + '/edit/' + _LiveProgramItemHashID, _data).then(_success, _error)
    }

    /**
     * 請求移除一個節目表項目
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async delProgramItem (_ProgramHashID, _LiveProgramItemHashID, _success, _error) {
        Vue.http.delete('live/program/item/' + _ProgramHashID + '/delete/' + _LiveProgramItemHashID).then(_success, _error)
    }
    /**
    * 取得客服項目清單
    * @param {*} _params 
    * @param {*} _success 
    * @param {*} _error 
    */
    async getCustomerServiceList (_params, _success, _error) {
        Vue.http.get('contact/list', { params: _params }).then(_success, _error)
    }

    /**
    * 取得單一客服項目
    * @param {*} _contactOrderHashID 
    * @param {*} _success 
    * @param {*} _error 
    */
    async getCustomerServiceDetail (_contactOrderHashID, _success, _error) {
        Vue.http.get(`contact/${_contactOrderHashID}/detail`).then(_success, _error)
    }

    /**
    * 對客服項目進行加註
    * @param {*} _contactOrderHashID 
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async postCustomerServiceComment (_contactOrderHashID, _data, _success, _error) {
        Vue.http.post(`contact/${_contactOrderHashID}/comment`, _data).then(_success, _error)
    }



    // ============ 直播管理－專區首頁編輯 ================

    /**
     *  取得大直播專區設定資料
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveConfig (_success, _error) {
        Vue.http.get('live/setting/region/live').then(_success, _error)
    }

    /**
    * 更新大直播影片設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateLiveVideoConfig (_data, _success, _error) {
        Vue.http.put('live/setting/livevideo', _data).then(_success, _error)
    }

    /**
    * 更新熱門直播設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateHotLiveConfig (_data, _success, _error) {
        Vue.http.put('live/setting/hotlive', _data).then(_success, _error)
    }

    /**
    * 更新特別企劃設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateSpecialPlanConfig (_data, _success, _error) {
        Vue.http.put('live/setting/specialplan', _data).then(_success, _error)
    }

    /**
    * 更新直播節目設定
    * @param {*} _data
    * @param {*} _success 
    * @param {*} _error 
    */
    async updateLiveProgramConfig (_data, _success, _error) {
        Vue.http.put('live/setting/liveprogram', _data).then(_success, _error)
    }





    // ============ 直播管理－專區首頁編輯 ================
    /**
    * 取得播放清單列表
    * @param {*} _success 
    * @param {*} _error 
    */
    async getPlayList (data, _success, _error) {
        Vue.http.get('live/playlist/list?page=' + data.page + '&type=' + data.type + '&sort_type=' + data.sort_type).then(_success, _error)
    }


    /**
     * 新增播放清單
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createPlayList (_data, _success, _error) {
        Vue.http.post('live/playlist/create', _data).then(_success, _error)
    }

    /**
    * 取得單一播放清單資訊
    * @param {*} _success 
    * @param {*} _error 
    */
    async getPlayListDetail (_id, _success, _error) {
        Vue.http.get('live/playlist/detail/' + _id).then(_success, _error)
    }

    /**
    * 取得單一播放清單列表
    * @param {*} _success 
    * @param {*} _error 
    */
    async getPlayListVideo (data, _success, _error) {
        Vue.http.get('live/playlist/videos/' + data.id + '?sort_type=' + data.sort_type).then(_success, _error)
    }

    /**
    * 更新單一播放清單資訊
    * @param {*} _success 
    * @param {*} _error 
    */
    async updatePlayListDetail (_id, _data, _success, _error) {
        Vue.http.put('live/playlist/detail/' + _id, _data).then(_success, _error)
    }

    /**
    * 刪除單一播放清單資訊
    * @param {*} _success 
    * @param {*} _error 
    */
    async deletePlayListDetail (_id, _success, _error) {
        Vue.http.delete('live/playlist/detail/' + _id).then(_success, _error)
    }

    /**
     * 取得節目清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getProgramList (_params, _success, _error) {
        Vue.http.get('program/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 取得單一節目詳情
     * @param {*} _success 
     * @param {*} _error 
     */
    async getProgramDetail (_id, _success, _error) {
        Vue.http.get('program/' + _id).then(_success, _error)
    }

    /**
     * 刪除單一節目項目
     * @param {*} _success 
     * @param {*} _error 
     */
    async deleteProgram (_id, _success, _error) {
        Vue.http.delete('program/' + _id).then(_success, _error)
    }

    /**
     * 獲得直播節目表模組清單
     * @param {*} _params 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveProgramList (_params, _success, _error) {
        Vue.http.get('live/program/list', {
            params: _params
        }).then(_success, _error)
    }

    /**
     * 取得目前啟用的播放清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getCurrentLiveProgramList (_success, _error) {
        Vue.http.get('live/program/current').then(_success, _error)
    }

    /**
     * 啟用指定的節目播放清單
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setCurrentLiveProgramList (_ProgramHashID, _success, _error) {
        Vue.http.post('live/program/start/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 移除指定的節目播放清單
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async delLiveProgramList (_ProgramHashID, _success, _error) {
        Vue.http.delete('live/program/delete/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 請求複製一個直播節目表
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async copyLiveProgramList (_ProgramHashID, _success, _error) {
        Vue.http.post('live/program/copy/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 取得一個節目表的資料
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveProgramDetail (_ProgramHashID, _success, _error) {
        Vue.http.get('live/program/detail/' + _ProgramHashID).then(_success, _error)
    }

    /**
     * 請求更新單一節目表資料
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putLiveProgramDetail (_ProgramHashID, _data, _success, _error) {
        Vue.http.put('live/program/edit/' + _ProgramHashID, _data).then(_success, _error)
    }

    /**
     * 請求建立一個直播節目表
     * @param {*} _success 
     * @param {*} _error 
     */
    async addLiveProgramDetail (_success, _error) {
        Vue.http.post('live/program/create').then(_success, _error)
    }

    /**
     * 取得一個節目表的項目清單
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getLiveProgramItemList (_ProgramHashID, _success, _error) {
        Vue.http.get('live/program/item/' + _ProgramHashID + '/list').then(_success, _error)
    }

    /**
     * 請求建立一個節目表項目
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async addProgramItem (_ProgramHashID, _data, _success, _error) {
        Vue.http.post('live/program/item/' + _ProgramHashID + '/create', _data).then(_success, _error)
    }

    /**
     * 請求更新一個節目表項目
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putProgramItem (_ProgramHashID, _LiveProgramItemHashID, _data, _success, _error) {
        Vue.http.put('live/program/item/' + _ProgramHashID + '/edit/' + _LiveProgramItemHashID, _data).then(_success, _error)
    }

    /**
     * 請求移除一個節目表項目
     * @param {*} _ProgramHashID 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async delProgramItem (_ProgramHashID, _LiveProgramItemHashID, _success, _error) {
        Vue.http.delete('live/program/item/' + _ProgramHashID + '/delete/' + _LiveProgramItemHashID).then(_success, _error)
    }

    /**
     * 請求獲取節目專區設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async getProgramPageSetting (_success, _error) {
        Vue.http.get('program/page/setting').then(_success, _error)
    }

    /**
     * 請求更新節目專區設定
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putProgramPageSetting (_data, _success, _error) {
        Vue.http.put('program/page/setting', _data).then(_success, _error)
    }

    /**
     * 請求發布節目專區設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async releaseProgramPageSetting (_success, _error) {
        Vue.http.post('program/page/setting').then(_success, _error)
    }

    /**
     * 獲得節目所有影片
     * @param {*} _ProgramHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getProgramVideoList (_ProgramHashID, _success, _error) {
        Vue.http.get('program/' + _ProgramHashID + '/videos').then(_success, _error)
    }

    /**
     * 請求獲取單一節目設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async getOneProgramPageSetting (id, _success, _error) {
        Vue.http.get('program/page/' + id).then(_success, _error)
    }

    /**
     * 更新單一節目設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async updateOneProgramPageSetting (id, _data, _success, _error) {
        Vue.http.put('program/page/' + id, _data).then(_success, _error)
    }

    /**
     * 發佈單一節目設定
     * @param {*} _success 
     * @param {*} _error 
     */
    async postOneProgramPageSetting (id, _success, _error) {
        Vue.http.post('program/page/' + id).then(_success, _error)
    }

    /**
     * 確認文章是否可以編輯
     * @param {*} _articleId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async checkArticleCanEditStatus (_articleId, _success, _error) {
        await Vue.http.post('news/can-edit/' + _articleId).then(_success, _error)
    }

    /**
     * 請求註銷文章編輯狀態
     * @param {*} _articleId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async resetArticleCanEditStatus (_articleId, _overwrite = false, _success, _error) {
        await Vue.http.post('news/leave-edit/' + _articleId + '?overwrite=' + _overwrite).then(_success, _error)
    }

    /**
     * 取得投票列表
     * @param {*} _params
     * @param {*} _success
     * @param {*} _error
     */
    async getVoteList (_params, _success, _error) {
        await Vue.http.get('polls/list', { params: _params }).then(_success, _error)
    }

    /**
     * 取得單一投票
     * @param {*} _pollHashID
     * @param {*} _success
     * @param {*} _error
     */
    async getVote (_pollHashID, _success, _error) {
        await Vue.http.get(`polls/item/${_pollHashID}`).then(_success, _error)
    }

    /**
     * 建立單一投票
     * @param {*} _data
     * @param {*} _success
     * @param {*} _error
     */
    async createVote (_data, _success, _error) {
        await Vue.http.post('polls/create', _data).then(_success, _error)
    }

    /**
     * 更新單一投票
     * @param {*} _pollHashID
     * @param {*} _data
     * @param {*} _success
     * @param {*} _error
     */
    async updateVote (_pollHashID, _data, _success, _error) {
        await Vue.http.put(`polls/item/${_pollHashID}`, _data).then(_success, _error)
    }

    /**
     * 刪除單一投票
     * @param {*} _pollHashID
     * @param {*} _success
     * @param {*} _error
     */
    async removeVote (_pollHashID, _success, _error) {
        await Vue.http.delete(`polls/item/${_pollHashID}`).then(_success, _error)
    }

    /**
     * 下架投票項目
     * @param {*} _pollHashID 
     * @param {*} _success 
     * @param {*} _error 
     */
    async offlineVote (_pollHashID, _success, _error) {
        await Vue.http.put('polls/offline/' + _pollHashID).then(_success, _error)
    }

    /**
     * 取得投票區目前設置
     * @param {*} _success
     * @param {*} _error
     */
    async getPollSetting (_success, _error) {
        await Vue.http.get('marketing/home/region/poll/setting').then(_success, _error)
    }

    /**
     * 暫存投票區目前設置
     * @param {*} _data
     * @param {*} _success
     * @param {*} _error
     */
    async updatePollSetting (_data, _success, _error) {
        await Vue.http.put('marketing/home/region/poll/setting', _data).then(_success, _error)
    }

    /**
     * 發布投票區目前設置
     * @param {*} _success
     * @param {*} _error
     */
    async releasePollSetting (_success, _error) {
        await Vue.http.post('marketing/home/region/poll/setting').then(_success, _error)
    }

    /**
     * 以 YT 清單網址獲得清單資訊
     * @param {*} _uri 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getYoutubePlayListDetail (_uri, _success, _error) {
        await Vue.http.post('live/playlist/detail', { youtube_playlist_uri: _uri }).then(_success, _error)
    }

    /**
     * 獲得 VVVIP 授權項目清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getVVVIPAuthorizeCaseList (_success, _error) {
        await Vue.http.get('coupon/vvvip/authorize-case/list').then(_success, _error)
    }

    /**
     * 取得選舉項目清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getElectionList (_success, _error) {
        await Vue.http.get('election/item/list').then(_success, _error)
    }

    async getElectionCandidateList (_ElectionHashID, _SearchOptions, _success, _error) {
        await Vue.http.get('election/candidate/' + _ElectionHashID + '/list', { params: _SearchOptions }).then(_success, _error)
    }

    async setCandidateCount (_data, _success, _error) {
        await Vue.http.post('election/candidate/magic', _data).then(_success, _error)
    }

    async getElectionPartys (_SearchOptions = {}, _success, _error) {
        await Vue.http.get('election/party/list', { params: _SearchOptions }).then(_success, _error)
    }

    async setPartyCount (_data, _success, _error) {
        await Vue.http.post('election/party/magic', _data).then(_success, _error)
    }

    async getMarketAutoSetting (_regionType, _params = {}, _success, _error) {
        await Vue.http.get('marketing/auto/setting/' + _regionType, { params: _params }).then(_success, _error)
    }

    async setMarketAutoSetting (_regionType, _data = {}, _success, _error) {
        await Vue.http.post('marketing/auto/setting/' + _regionType, _data).then(_success, _error)
    }

    async getArticleCache (_articleId, _success, _error) {
        await Vue.http.get('news/cache/' + _articleId).then(_success, _error)
    }

    async setArticleCache (_articleId, _data, _success, _error) {
        await Vue.http.post('news/cache/' + _articleId, _data).then(_success, _error)
    }

    async getDisasterUnitList (_success, _error) {
        await Vue.http.get('disaster/unit/list').then(_success, _error)
    }

    async getAlertList (_params, _success, _error) {
        await Vue.http.get('disaster/alert/list', { params: _params }).then(_success, _error)
    }

    async getGuideLinks (_success, _error) {
        await Vue.http.get('news/guide-links').then(_success, _error)
    }

    async setGuideLinks (_data, _success, _error) {
        await Vue.http.put('news/guide-links', _data).then(_success, _error)
    }

    async setEditorName (_editorName, _success, _error) {
        await Vue.http.put('manager/editorName/update', { editor_name: _editorName }).then(_success, _error)
    }

    async setCenterType (_centerType, _success, _error) {
        await Vue.http.put('manager/editorCenter/update', { center_type: _centerType }).then(_success, _error)
    }

    async getActiveUsers (_sourceType, _siteType, _success, _error) {
        await Vue.http.post('analytics/active-users/' + _sourceType, {
            site: _siteType
        }).then(_success, _error)
    }

    async getGoogleTrendsList (_success, _error) {
        await Vue.http.get('analytics/google-trends').then(_success, _error)
    }

    async getUserRegionList (_siteType, _success, _error) {
        await Vue.http.post('analytics/user-regions', {
            site: _siteType
        }).then(_success, _error)
    }

    async getUserReferrersList (_siteType, _RangeType, _success, _error) {
        await Vue.http.post('analytics/user-referrers/' + _RangeType, {
            site: _siteType
        }).then(_success, _error)
    }

    async getHotArticle (_min = 30, _success, _error) {
        await Vue.http.get('analytics/hot-article', { params: { min: _min } }).then(_success, _error)
    }

    async getHotTypePage (_min = 30, _success, _error) {
        await Vue.http.get('analytics/hot-type-page', { params: { min: _min } }).then(_success, _error)
    }

    async getHotRssArticle (_min = 30, _success, _error) {
        await Vue.http.get('analytics/hot-rss-article', { params: { min: _min } }).then(_success, _error)
    }

    async getHotAuthors (_success, _error) {
        await Vue.http.get('analytics/hot-authors').then(_success, _error)
    }
}